import React from "react";
import { CpOverlay, CpEmptyState, CpButton } from "canopy-styleguide!sofe";
import canopyUrls from "canopy-urls!sofe";

export default function ContinueIntegrationPage({ code, realmId, state }) {
  const closeWindow = () => {
    return window.close();
  };

  const continueIntegration = () => {
    return window.location.replace(
      `${canopyUrls.getWorkflowUrl()}/wg/accounting-qbo-oauth-redirect?code=${code}&state=${state}&realmId=${realmId}&changeAccount=true`,
    );
  };

  return (
    <CpOverlay show onClose={closeWindow}>
      <CpOverlay.Header title="Integration Setup" />
      <CpOverlay.Body>
        <CpEmptyState
          img="es_caution"
          text="This is a new QBO account"
          subText="In order to continue, you will need to unlink all data between your previous QBO connection and Canopy. This cannot be undone."
          cta={
            <>
              <CpButton btnType="primary" onClick={continueIntegration}>
                Unlink data
              </CpButton>
              <CpButton
                btnType="secondary"
                onClick={closeWindow}
                className="cp-ml-8"
              >
                Cancel
              </CpButton>
            </>
          }
        />
      </CpOverlay.Body>
    </CpOverlay>
  );
}
